import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import "moment/min/locales.min.js";
import { Card, Image, Button, Popup, Label, Icon } from "semantic-ui-react";

import green from "images/green.png";
import orange from "images/orange.png";
import red from "images/red.png";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";

/**
 * Get correct image based on time interval between now and last_tmst of dataflow
 * @function renderDispoIcon
 * @param {string} last_tmst_str - datetime string represention
 */
const renderDispo = (last_tmst_str, sensor_type) => {
    if (!last_tmst_str) {
        return { icon: null, color: "grey" };
    }
    const now = moment();
    const last_tmst = moment(last_tmst_str);

    if (!last_tmst.isValid()) {
        return { icon: null, color: "grey" };
    }

    const low_level = sensor_type === "imp" ? 24 : 3;
    const mid_level = sensor_type === "imp" ? 48 : 24;

    if (now.diff(last_tmst, "hours") <= low_level) {
        return { icon: green, color: "green" };
    } else if (now.diff(last_tmst, "hours") <= mid_level) {
        return { icon: orange, color: "orange" };
    } else {
        return { icon: red, color: "red" };
    }
};

export const DispoInfo = React.memo((props) => {
    const { display, equipment, lastActivity } = props;
    const current_lng = useSelector((state) => state.i18n.current);

    if (lastActivity?.isFetching) {
        return (
            <>
                {_.isEqual(display, "card") && (
                    <MessageDisplay message={i18n._(t`update`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                )}
                {_.isEqual(display, "button") && (
                    <Popup content={i18n._(t`update`)} trigger={<Button compact basic icon="circle notched" loading />} />
                )}
            </>
        );
    }

    if (lastActivity?.isError) {
        return (
            <>
                {_.isEqual(display, "card") && (
                    <MessageDisplay message={i18n._(t`unavailable`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />
                )}
                {_.isEqual(display, "button") && (
                    <Popup content={i18n._(t`unavailable`)} trigger={<Button compact basic icon="warning" color="red" />} />
                )}
            </>
        );
    }

    if (lastActivity?.isSuccess) {
        const sensors = lastActivity.data?.[equipment.id]?.sensors ?? [];

        //most recent sensor with a last_tmst string
        const recent_sensor = _.chain(sensors)
            .filter((item) => item.last_tmst !== undefined)
            .orderBy(["last_tmst"], ["desc"])
            .head()
            .defaultTo(null)
            .value();

        const display_icon = renderDispo(recent_sensor?.last_tmst, recent_sensor?.type);

        let renderIcon = null;
        let renderHeader = null;
        let renderSensors = null;

        if (_.size(sensors) === 0) {
            renderHeader = (
                <Card.Header textAlign="left">
                    <Trans>no data on server</Trans>
                </Card.Header>
            );
        }

        //at least one sensor or imp ?
        if (_.some(sensors, { type: "sensor" }) || _.some(sensors, { type: "imp" })) {
            if (display_icon.icon !== null) {
                renderIcon = <Image src={display_icon.icon} size="mini" floated="right" />;
                renderHeader = (
                    <>
                        <Card.Header textAlign="left">
                            {moment(recent_sensor.last_tmst).locale(current_lng).fromNow()}
                            {_.isEqual(display, "card") && (
                                <Popup position="top center" trigger={<Icon name="question circle" color="grey" size="small" />}>
                                    <Popup.Content>
                                        {_.every(sensors, { type: "imp" }) && <Trans>Last date from imp</Trans>}
                                        {!_.every(sensors, { type: "imp" }) && <Trans>Last value from sensor</Trans>}
                                    </Popup.Content>
                                </Popup>
                            )}
                        </Card.Header>
                        <Card.Content extra>{moment(recent_sensor.last_tmst).locale(current_lng).format("LLL")}</Card.Content>
                    </>
                );
            } else {
                renderIcon = _.some(sensors, { type: "sensor" }) && <Image src={red} size="mini" floated="right" />;
                renderHeader = (
                    <Card.Header textAlign="left">
                        <Trans>no data on server</Trans>
                    </Card.Header>
                );
            }
            renderSensors = _.some(sensors, { type: "sensor" }) && (
                <Card.Content extra textAlign="right">
                    <Label.Group size={"small"}>
                        {_.map(sensors, (sensor, idx) => {
                            if (sensor?.identifier) {
                                return (
                                    <Label key={idx} color={renderDispo(sensor.last_tmst).color}>
                                        {sensor.identifier}
                                    </Label>
                                );
                            }
                            return null;
                        })}
                    </Label.Group>
                </Card.Content>
            );
        } else if (_.some(sensors, { type: "syn" })) {
            renderIcon = <Icon size="large" name="code branch" style={{ float: "right" }} />;
            renderHeader = (
                <Card.Header textAlign="left">
                    <Trans>calculated measure</Trans>
                </Card.Header>
            );
        }
        if (display === "card") {
            return (
                <Card fluid className="pwaDispoInfoCard">
                    <Card.Content>
                        {renderIcon}
                        {renderHeader}
                    </Card.Content>
                    {renderSensors}
                </Card>
            );
        } else if (display === "button") {
            return (
                <Popup trigger={renderIcon} position="bottom right" className="pwaDispoInfoPopup">
                    <Popup.Content>
                        <Card fluid className="pwaDispoInfoCard">
                            <Card.Content>{renderHeader}</Card.Content>
                            {renderSensors}
                        </Card>
                    </Popup.Content>
                </Popup>
            );
        } else {
            return null;
        }
    }
    return null;
});

DispoInfo.defaultProps = {
    display: "card"
};

DispoInfo.propTypes = {
    display: PropTypes.string.isRequired,
    equipment: PropTypes.object.isRequired
};

export default DispoInfo;
