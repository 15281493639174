import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { checkRights } from "modules/auth/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { validateNumber } from "modules/common/utils";
import { useUpdateEquipmentMutation } from "modules/equipment/equipmentService";

import { InputAdapter, TextAreaAdapter } from "modules/common/components/form";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const EquipmentSecondaryForm = (props) => {
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);

    const [updateEquipment, update] = useUpdateEquipmentMutation();

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update equipment`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update equipment`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        return {
            equipment: props.equipment
        };
    }, [props.equipment]);

    const can_change = useMemo(() => {
        const rights = checkRights(props?.equipment, auth.rights);
        // TODO CHANGE for add Equipment. Site not define. Need another way to get right.
        // Now: validate btn enable for add. Backend check rights
        return _.includes(rights, "can_change");
    }, [auth.rights, props.equipment]);

    const submitForm = (formData) => {
        updateEquipment({ org: org.current, data: formData?.equipment });
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={14} computer={14}>
                                    <Grid verticalAlign="top">
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.internal_ref"
                                                placeholder={i18n._(t`enter internal_ref of equipment`)}
                                                label={i18n._(t`internal_ref`)}
                                                component={InputAdapter}
                                                defaultValue=""
                                                parse={identity}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.brand"
                                                placeholder={i18n._(t`enter brand of equipment`)}
                                                label={i18n._(t`brand`)}
                                                component={InputAdapter}
                                                defaultValue=""
                                                parse={identity}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.model"
                                                placeholder={i18n._(t`enter model of equipment`)}
                                                label={i18n._(t`model`)}
                                                component={InputAdapter}
                                                defaultValue=""
                                                parse={identity}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.manufacturer_SN"
                                                placeholder={i18n._(t`enter manufacturer_sn of equipment`)}
                                                label={i18n._(t`manufacturer_SN`)}
                                                component={InputAdapter}
                                                defaultValue=""
                                                parse={identity}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.manufacture_year"
                                                placeholder={i18n._(t`enter manufacture_year of equipment`)}
                                                label={i18n._(t`manufacture_year`)}
                                                component={InputAdapter}
                                                defaultValue={moment().year()}
                                                parse={identityNull}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, true, true);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.maintenance_manager"
                                                placeholder={i18n._(t`enter maintenance_manager of equipment`)}
                                                label={i18n._(t`maintenance_manager`)}
                                                component={InputAdapter}
                                                defaultValue=""
                                                parse={identity}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="equipment.description"
                                                placeholder={i18n._(t`enter description of your equipment`)}
                                                label={i18n._(t`description`)}
                                                component={TextAreaAdapter}
                                                defaultValue=""
                                                parse={identity}
                                                rows={3}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                    {(can_change || (props?.equipment?.id === undefined && _.size(auth.rights?.sites_rw) > 0)) &&
                                        notification.srv_status.db_status === "rw" && (
                                            <>
                                                <Divider />
                                                <Segment basic>
                                                    <Button
                                                        type="submit"
                                                        positive
                                                        content={i18n._(t`validate`)}
                                                        disabled={submitting || pristine || invalid}
                                                    />
                                                </Segment>
                                            </>
                                        )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                );
            }}
        />
    );
};

export default React.memo(EquipmentSecondaryForm);
