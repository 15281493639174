import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Form } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Button, Divider, Icon, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { isPlainObject, reparseNumber } from "modules/common/utils";
import { default_sum_formula } from "../advanced/SyntheticFormulaAdapter";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useCreateEquipmentMeasurementMutation, useUpdateEquipmentMeasurementMutation } from "modules/equipment/equipmentService";

import MeasurementFields from "modules/equipment/components/form/MeasurementFields";
import RequestErrorRender from "modules/common/components/RequestErrorRender";
import { formulaTypeEnum, syndataflowtypeEnum } from "modules/equipment/utils";

const default_syndataflow = {
    formula: default_sum_formula,
    syndataflowtype: syndataflowtypeEnum.DERIVATIVEPOSITIVE,
    formula_type: formulaTypeEnum.SUMDIFFERENCE
};

const MeasurementForm = (props) => {
    const { setOpen, measurement, equipment, measurementsObject } = props;
    const org = useSelector((state) => state.org);

    const [updateEquipmentMeasure, update] = useUpdateEquipmentMeasurementMutation();
    const [createEquipmentMeasure, create] = useCreateEquipmentMeasurementMutation();

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create measurement`), toast_options);
            setOpen && setOpen(false);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create measurement`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create, setOpen]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update measurement`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update measurement`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        let syndataflow = default_syndataflow;

        //In this form, syndataflowtype is splitted into 2 fields (formula_type && syndataflowtype) for client usage
        if (measurement?.syndataflow) {
            //syndataflow exists
            if (
                _.includes([syndataflowtypeEnum.DERIVATIVENEGATIVE, syndataflowtypeEnum.DERIVATIVEPOSITIVE], measurement.syndataflow?.syndataflowtype)
            ) {
                syndataflow = { ...syndataflow, ...measurement.syndataflow };
            } else if (measurement.syndataflow?.syndataflowtype === syndataflowtypeEnum.HEATENERGY) {
                //Product energy => formula_type 2
                syndataflow = { ...syndataflow, ...measurement.syndataflow, formula_type: formulaTypeEnum.HEATENERGY };
            } else if (measurement.syndataflow?.syndataflowtype === syndataflowtypeEnum.GENERICPRODUCT) {
                //Prouct generic => formula_type 3
                syndataflow = { ...syndataflow, ...measurement.syndataflow, formula_type: formulaTypeEnum.GENERICPRODUCT };
            }
        }

        return {
            equipment,
            dataflow: {
                name: measurement?.name ?? null,
                dataflowspec: measurement?.dataflowspec ?? null,
                standby_threshold: measurement?.standby_threshold ?? null
            },
            syndataflow,
            measurement: {
                display_unit: measurement?.display_unit || -1,
                measurementtype: measurement?.measurementtype ?? null,
                factor: measurement?.factor ?? 1,
                offset: measurement?.offset ?? 0,
                minGauge: measurement?.minGauge ?? 0,
                maxGauge: measurement?.maxGauge ?? 1000
            }
        };
    }, [measurement, equipment]);

    const isSynthetic = measurement === undefined || (measurement?.syndataflow ?? null) !== null;
    const isPulse = (measurement?.dataflowspec_tech ?? null) === 8; //pk:8 => pulse

    const submitForm = (formData) => {
        const data = _.mapValues(formData, (item, key) => {
            if (key === "measurement") {
                return _.mapValues(item, (field, field_key) => {
                    if (field_key === "display_unit" && field === -1) {
                        return null;
                    }
                    if (_.includes(["offset", "factor", "minGauge", "maxGauge"], field_key)) {
                        return reparseNumber(field);
                    }
                    return field;
                });
            }
            if (key === "dataflow") {
                return _.mapValues(item, (field, field_key) => {
                    if (field_key === "standby_threshold") {
                        return reparseNumber(field);
                    }
                    return field;
                });
            }
            if (key === "syndataflow") {
                if (measurement?.syndataflow === null) {
                    return null;
                }
                const syndataflow = _.mapValues(item, (field, field_key, all_fields) => {
                    if (field_key === "formula") {
                        if (isPlainObject(field)) {
                            return _.mapValues(field, (formula_field, formula_field_key) => {
                                if (_.includes(["m_a", "m_b", "m_c", "f_a"], formula_field)) {
                                    return reparseNumber(field);
                                }
                                return formula_field;
                            });
                        }
                        if (Array.isArray(field)) {
                            return _.map(field, (line_formula) => {
                                if (line_formula?.type === "factor") {
                                    return {
                                        ...line_formula,
                                        measure: reparseNumber(line_formula.measure),
                                        factor: reparseNumber(line_formula.factor)
                                    };
                                }
                                return { ...line_formula, measure: reparseNumber(line_formula.measure) };
                            });
                        }
                    }
                    if (field_key === "syndataflowtype") {
                        //update syndataflowtype based on formula_type
                        return (
                            {
                                2: syndataflowtypeEnum.HEATENERGY,
                                3: syndataflowtypeEnum.GENERICPRODUCT
                            }[all_fields?.formula_type] ?? field
                        );
                    }
                    return field;
                });
                return _.omit(syndataflow, ["formula_type"]);
            }
            return item;
        });
        if (measurement !== undefined) {
            updateEquipmentMeasure({
                org: org.current,
                data: _.omit(data, ["equipment"]), //remove equipment object for submit. Used for synthetic site usage
                equipment_id: equipment.id,
                measurement_id: measurement.id
            });
        } else {
            createEquipmentMeasure({ org: org.current, data: _.omit(data, ["equipment"]), equipment_id: equipment.id });
        }
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <Segment attached>
                        <form onSubmit={handleSubmit} className="ui form">
                            <MeasurementFields
                                form={form}
                                values={values}
                                isSynthetic={isSynthetic}
                                isPulse={isPulse}
                                measurementsObject={measurementsObject}
                            />
                            <Segment basic textAlign="right">
                                <Divider />
                                {setOpen && (
                                    <Button type="button" negative icon labelPosition="left" onClick={(e) => setOpen(false)}>
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                )}
                                <Button positive type="submit" disabled={submitting || pristine || invalid}>
                                    <Trans>Confirm</Trans>
                                </Button>
                            </Segment>
                        </form>
                    </Segment>
                );
            }}
        />
    );
};

export default React.memo(MeasurementForm);
