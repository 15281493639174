import React, { useCallback } from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Icon, Popup, Segment, Grid, Divider, Button, Dropdown, Dimmer, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

import FactorMeasureItem from "./FactorMeasureItem";
import MeasureItem from "./MeasureItem";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DropdownMeasurementHeatGeneric from "./DropdownMeasurementHeatGeneric";

const operatorList = [
    { key: "+", value: "+", text: "+" },
    { key: "-", value: "-", text: "-" }
];

const processList = [
    { key: "measure", value: "measure", text: <Trans>measure</Trans>, icon: null },
    { key: "factor", value: "factor", text: <Trans>factor</Trans>, icon: null }
];

export const default_sum_formula = [
    { operator: "+", type: "measure", measure: null },
    { operator: "+", type: "measure", measure: null }
];
export const default_heat_formula = { f_a: 1.0, m_a: null, m_b: null, m_c: null };
export const default_generic_formula = { f_a: 1.0, m_a: null, m_b: null };

const SumDifferenceFormula = React.memo((props) => {
    const { input, meta, values, measurementsObject } = props;
    const formula = input.value || default_sum_formula;

    const changeProcess = useCallback(
        (idx, data) => {
            const new_formula = _.map(formula, (item, index) => {
                if (index !== idx) {
                    return item;
                }
                switch (data.value) {
                    case "measure":
                        return { operator: item.operator, type: data.value, measure: null };
                    case "factor":
                        return { operator: item.operator, type: data.value, measure: null, factor: 1 };
                    default:
                        return item;
                }
            });
            input.onChange(new_formula);
        },
        [formula, input]
    );

    const changeOperator = useCallback(
        (idx, data) => {
            const new_formula = _.map(formula, (item, index) => {
                if (index !== idx) {
                    return item;
                }
                return { ...item, operator: data.value };
            });
            input.onChange(new_formula);
        },
        [formula, input]
    );

    const removeOperation = (idx) => {
        const new_formula = _.filter(formula, (item, index) => index !== idx);
        input.onChange(new_formula);
    };

    const display_err = meta.dirty && meta.invalid;

    return _.map(formula, (node, idx) => {
        const line_err = meta.error?.formula?.[idx];
        return (
            <React.Fragment key={idx}>
                {display_err && line_err !== undefined && (
                    <Grid.Row>
                        {node.type === null && line_err?.process_type && (
                            <Grid.Column width={4} textAlign="center">
                                <Label basic color="red" pointing="below">
                                    {line_err?.process_type}
                                </Label>
                            </Grid.Column>
                        )}
                        {node.type === "measure" && line_err?.measure && (
                            <Grid.Column width={16} textAlign="center">
                                <Label basic color="red" pointing="below">
                                    {line_err?.measure}
                                </Label>
                            </Grid.Column>
                        )}
                        {node.type === "factor" && (
                            <>
                                <Grid.Column width={4} />
                                <Grid.Column width={2}>
                                    {line_err?.factor && (
                                        <Label basic color="red" pointing="below">
                                            {line_err?.factor}
                                        </Label>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={1} />
                                <Grid.Column width={8}>
                                    {line_err?.measure && (
                                        <Label basic color="red" pointing="below">
                                            {line_err?.measure}
                                        </Label>
                                    )}
                                </Grid.Column>
                            </>
                        )}
                    </Grid.Row>
                )}
                <Grid.Row>
                    {idx === 0 && <Grid.Column width={1} />}
                    {idx !== 0 && (
                        <Grid.Column width={1}>
                            <Dropdown
                                fluid
                                options={operatorList}
                                selection
                                onChange={(e, data) => {
                                    changeOperator(idx, data);
                                }}
                                value={node.operator}
                                upward
                            />
                        </Grid.Column>
                    )}
                    {node.type === "measure" && (
                        <>
                            <Grid.Column width={3}>
                                <Dropdown
                                    fluid
                                    options={processList}
                                    selection
                                    onChange={(e, data) => changeProcess(idx, data)}
                                    value={node.type}
                                    upward
                                />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <MeasureItem
                                    idx={idx}
                                    node={node}
                                    formula={formula}
                                    input={input}
                                    values={values}
                                    measurementsObject={measurementsObject}
                                />
                            </Grid.Column>
                        </>
                    )}
                    {node.type === "factor" && (
                        <>
                            <Grid.Column width={3}>
                                <Dropdown
                                    fluid
                                    options={processList}
                                    selection
                                    onChange={(e, data) => changeProcess(idx, data)}
                                    value={node.type}
                                    upward
                                />
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <FactorMeasureItem
                                    idx={idx}
                                    node={node}
                                    formula={formula}
                                    input={input}
                                    values={values}
                                    measurementsObject={measurementsObject}
                                />
                            </Grid.Column>
                        </>
                    )}
                    {node.type === null && (
                        <>
                            <Grid.Column width={3}>
                                <Dropdown
                                    fluid
                                    options={processList}
                                    selection
                                    onChange={(e, data) => changeProcess(idx, data)}
                                    value={node.type}
                                    upward
                                />
                            </Grid.Column>
                            <Grid.Column width={10} />
                        </>
                    )}
                    {idx !== 0 && (
                        <Grid.Column width={1}>
                            <Button
                                type="button"
                                icon="delete"
                                negative
                                onClick={(e) => {
                                    removeOperation(idx);
                                }}
                            />
                        </Grid.Column>
                    )}
                </Grid.Row>
            </React.Fragment>
        );
    });
});

const HeatEnergyFormula = React.memo((props) => {
    const { input, meta, values, measurementsObject } = props;
    const formula = input.value || default_heat_formula;

    const onChangeFactor = (evt, data) => {
        input.onChange({ ...formula, f_a: evt.target.value });
    };

    const display_err = meta.dirty && meta.invalid;

    return (
        <>
            {display_err && (meta.error?.formula?.f_a || meta.error?.formula?.m_a) && (
                <Grid.Row>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column mobile={11} tablet={11} computer={11}>
                        <Grid verticalAlign="middle">
                            <Grid.Column width={3}>
                                {meta.error?.formula?.f_a && (
                                    <Label basic color="red" pointing="below">
                                        {meta.error?.formula?.f_a}
                                    </Label>
                                )}
                            </Grid.Column>
                            <Grid.Column width={1} />
                            <Grid.Column width={12}>
                                {meta.error?.formula?.m_a && (
                                    <Label basic color="red" pointing="below">
                                        {meta.error?.formula?.m_a}
                                    </Label>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={2} tablet={2} computer={2} />
                <Grid.Column mobile={3} tablet={3} computer={3}>
                    <Input value={i18n._(processList[1].value)} readOnly />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={11}>
                    <Grid verticalAlign="middle">
                        <Grid.Column width={3}>
                            <div className={`ui fluid input ${!_.isUndefined(props.factor_err) ? "field error" : ""}`}>
                                <input type="text" onChange={onChangeFactor} value={formula?.f_a ?? ""} autoComplete="off" />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={1}>*</Grid.Column>
                        <Grid.Column width={12}>
                            <DropdownMeasurementHeatGeneric
                                formula_key={"m_a"}
                                formula={formula}
                                input={input}
                                values={values}
                                measurementsObject={measurementsObject}
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            {display_err && meta.error?.formula?.m_b && (
                <Grid.Row>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column mobile={11} tablet={11} computer={11}>
                        <Grid verticalAlign="middle">
                            <Grid.Column width={16}>
                                <Label basic color="red" pointing="below">
                                    {meta.error?.formula?.m_b}
                                </Label>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={2} tablet={2} computer={2}>
                    <Input value={"x"} readOnly />
                </Grid.Column>
                <Grid.Column mobile={3} tablet={3} computer={3} />
                <Grid.Column mobile={11} tablet={11} computer={11}>
                    <DropdownMeasurementHeatGeneric
                        formula_key={"m_b"}
                        formula={formula}
                        input={input}
                        values={values}
                        measurementsObject={measurementsObject}
                        intensive={true}
                    />
                </Grid.Column>
            </Grid.Row>
            {display_err && meta.error?.formula?.m_c && (
                <Grid.Row>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column mobile={11} tablet={11} computer={11}>
                        <Grid verticalAlign="middle">
                            <Grid.Column width={16}>
                                <Label basic color="red" pointing="below">
                                    {meta.error?.formula?.m_c}
                                </Label>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={2} tablet={2} computer={2} />
                <Grid.Column mobile={3} tablet={3} computer={3}>
                    <Input value={"-"} readOnly />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={11}>
                    <DropdownMeasurementHeatGeneric
                        formula_key={"m_c"}
                        formula={formula}
                        input={input}
                        values={values}
                        measurementsObject={measurementsObject}
                        intensive={true}
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    );
});

const GenericProductFormula = React.memo((props) => {
    const { input, meta, values, measurementsObject } = props;
    const formula = input.value || default_heat_formula;

    const onChangeFactor = (evt, data) => {
        input.onChange({ ...formula, f_a: evt.target.value });
    };

    const display_err = meta.dirty && meta.invalid;

    return (
        <>
            {display_err && (meta.error?.formula?.f_a || meta.error?.formula?.m_a) && (
                <Grid.Row>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column mobile={11} tablet={11} computer={11}>
                        <Grid verticalAlign="middle">
                            <Grid.Column width={3}>
                                {meta.error?.formula?.f_a && (
                                    <Label basic color="red" pointing="below">
                                        {meta.error?.formula?.f_a}
                                    </Label>
                                )}
                            </Grid.Column>
                            <Grid.Column width={1} />
                            <Grid.Column width={12}>
                                {meta.error?.formula?.m_a && (
                                    <Label basic color="red" pointing="below">
                                        {meta.error?.formula?.m_a}
                                    </Label>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={2} tablet={2} computer={2} />
                <Grid.Column mobile={3} tablet={3} computer={3}>
                    <Input value={i18n._(processList[1].value)} readOnly />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={11}>
                    <Grid verticalAlign="middle">
                        <Grid.Column width={3}>
                            <div className={`ui fluid input ${!_.isUndefined(props.factor_err) ? "field error" : ""}`}>
                                <input type="text" onChange={onChangeFactor} value={formula?.f_a ?? ""} autoComplete="off" />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={1}>*</Grid.Column>
                        <Grid.Column width={12}>
                            <DropdownMeasurementHeatGeneric
                                formula_key={"m_a"}
                                formula={formula}
                                input={input}
                                values={values}
                                measurementsObject={measurementsObject}
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            {display_err && meta.error?.formula?.m_b && (
                <Grid.Row>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column mobile={11} tablet={11} computer={11}>
                        <Grid verticalAlign="middle">
                            <Grid.Column width={16}>
                                <Label basic color="red" pointing="below">
                                    {meta.error?.formula?.m_b}
                                </Label>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column mobile={2} tablet={2} computer={2}>
                    <Input value={"x"} readOnly />
                </Grid.Column>
                <Grid.Column mobile={3} tablet={3} computer={3}>
                    <Input value={i18n._(processList[0].value)} readOnly />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={11}>
                    <DropdownMeasurementHeatGeneric
                        formula_key={"m_b"}
                        formula={formula}
                        input={input}
                        values={values}
                        measurementsObject={measurementsObject}
                        intensive={true}
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    );
});

const SyntheticFormulaAdapter = (props) => {
    const { isRequired, cssOverride, helperText, label, input, values, measurementsObject, meta } = props;
    const as_warning =
        !_.isFinite(values?.equipment?.site) || !_.isFinite(values?.dataflow?.dataflowspec) || !_.isFinite(values?.measurement?.measurementtype);

    const formula_type = values?.syndataflow?.formula_type ?? 1;
    let formula = input.value;
    if (_.size(formula) === 0) {
        if (formula_type === 1) {
            formula = default_sum_formula;
        }
    }

    return (
        <div className={`field ${isRequired ? "required" : ""} ${cssOverride ? "pwaFormField" : ""}`}>
            {label && <label style={{ display: "inline" }}>{label}</label>}
            {helperText && (
                <Popup basic trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}>
                    <Segment basic style={{ width: "35vh" }}>
                        {helperText}
                    </Segment>
                </Popup>
            )}
            <Dimmer.Dimmable dimmed={true} style={{ zIndex: 0, overflow: "visible" }}>
                <Dimmer inverted active={as_warning}>
                    {!_.isFinite(values?.equipment?.site) && (
                        <MessageDisplay message={i18n._(t`select site`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    {!_.isFinite(values?.dataflow?.dataflowspec) && (
                        <MessageDisplay message={i18n._(t`select dataflowspec`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    {!_.isFinite(values?.measurement?.measurementtype) && (
                        <MessageDisplay message={i18n._(t`Select measurementtype`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                </Dimmer>
                <Segment attached style={{ background: "rgba(0, 151, 19, 0.1)" }}>
                    <Grid>
                        {formula_type === 1 && (
                            <SumDifferenceFormula input={input} meta={meta} values={values} measurementsObject={measurementsObject} />
                        )}
                        {formula_type === 2 && (
                            <HeatEnergyFormula input={input} meta={meta} values={values} measurementsObject={measurementsObject} />
                        )}
                        {formula_type === 3 && (
                            <GenericProductFormula input={input} meta={meta} values={values} measurementsObject={measurementsObject} />
                        )}
                        {/* Only display add/remove button for 'SUM/DIFFERENCE' formula type */}
                        {formula_type === 1 && (
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Divider />
                                </Grid.Column>
                                <Grid.Column mobile={2} tablet={2} computer={1}>
                                    {
                                        <Button.Group>
                                            <Button
                                                type="button"
                                                icon="add"
                                                positive
                                                onClick={(e) => {
                                                    const new_nodes = { operator: "+", type: null };
                                                    input.onChange([...formula, new_nodes]);
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                icon="delete"
                                                negative
                                                disabled={_.size(formula) <= 2}
                                                onClick={(e) => {
                                                    if (_.size(formula) > 2) {
                                                        const new_formula = _.dropRight(formula, 1);
                                                        input.onChange(new_formula);
                                                    }
                                                }}
                                            />
                                        </Button.Group>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </Segment>
            </Dimmer.Dimmable>
        </div>
    );
};

export default React.memo(SyntheticFormulaAdapter);
