import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import Landing from "./Landing";
import Page404 from "./Page404";

import OtpRoute from "modules/common/components/OtpRoute";
import Comparison from "modules/dashboard/components/comparison/Comparison";
//Updated
import Cover from "modules/report/components/Cover";
import PreloadEquipments from "modules/equipment/components/preload/PreloadEquipments";
import PreloadSensors from "modules/sensor/components/preload/PreloadSensors";
import PreloadSensor from "modules/sensor/components/preload/PreloadSensor";
import PreloadExports from "modules/export/components/preload/PreloadExports";
import PreloadExport from "modules/export/components/preload/PreloadExport";
import PreloadAlerts from "modules/alert/alert/components/preload/PreloadAlerts";
import PreloadAlert from "modules/alert/alert/components/preload/PreloadAlert";
import PreloadAlertPacks from "modules/alert/alert_pack/components/preload/PreloadAlertPacks";
import PreloadAlertPack from "modules/alert/alert_pack/components/preload/PreloadAlertPack";
import PreloadGlobalview from "modules/globalview/components/preload/PreloadGlobalview";
import PreloadKinematic from "modules/predict/cinematic/components/PreloadKinematic";
import AlertEvents from "modules/alert/alert_event/components/AlertEvents";
import MachineEvent from "modules/machine/components/event/MachineEvent";
import MachineEvents from "modules/machine/components/event/MachineEvents";
import Declaration from "modules/machine/components/maintenance/Declaration";
import AnalysisAdvanced from "modules/analysisAdvanced/components/AnalysisAdvanced";
import Sites from "modules/site/components/Sites";
import Site from "modules/site/components/Site";
import Mailings from "modules/mailing/components/Mailings";
import Mailing from "modules/mailing/components/Mailing";
import Reports from "modules/report/components/Reports";
import Report from "modules/report/components/Report";
import OtpSetup from "modules/auth/components/OtpSetup";
import Users from "modules/user/components/Users";
import User from "modules/user/components/User";
import OtpValidate from "modules/auth/components/OtpValidate";
import Hierarchy from "modules/hierarchy/components/Hierarchy";
import LandingAdmin from "modules/admin/components/Landing";
import Tags from "modules/tag/components/Tags";
import EnergySavings from "modules/energysaving/components/EnergySavings";
import EnergySaving from "modules/energysaving/components/EnergySaving";
import EnergySavingDetail from "modules/energysaving/components/EnergySavingDetail";
import Usages from "modules/usage/components/Usages";
import Organization from "modules/organization/components/Organization";
import DashboardDnd from "modules/dashboardDragNDrop/components/DashboardDnd";
import Imports from "modules/import/components/Imports";
import Import from "modules/import/components/Import";
import File from "modules/import/components/File";
import ApiImports from "modules/import/components/ApiImports";
import ApiImport from "modules/import/components/ApiImport";
import Overview from "modules/overview/components/Overview";
import ActivityLog from "modules/activityLog/component/ActivityLog";
import Dashboard from "modules/dashboard/components/Dashboard";
import ImportedDataSets from "modules/importedDataset/components/ImportedDataSets";
import ProductionDatas from "modules/importedDataset/components/ProductionDatas";
import Equipment from "modules/equipment/components/Equipment";

export const RoutingLayer = ({ path, auth }) => {
    const rights = auth?.rights;

    return (
        <Switch>
            <OtpRoute path={`${path}`} component={Landing} exact />
            <OtpRoute path={`${path}comparison`} component={Comparison} exact />
            {/* Slice usage */}
            <OtpRoute path={`${path}dashboard`} component={Dashboard} exact />
            <OtpRoute path={`${path}overview/:id_eqpmt([0-9]+)`} component={Overview} exact />
            <OtpRoute path={`${path}equipments`} component={PreloadEquipments} exact />
            <OtpRoute path={`${path}equipments/:id_eqpmt([0-9]+)`} component={Equipment} exact />
            <OtpRoute path={`${path}exports`} component={PreloadExports} exact />
            <OtpRoute path={`${path}exports/add`} component={PreloadExport} exact />
            <OtpRoute path={`${path}exports/:id([0-9]+)`} component={PreloadExport} exact />
            <OtpRoute path={`${path}energysavings`} component={EnergySavings} exact />
            <OtpRoute path={`${path}energysavings/add`} component={EnergySaving} exact />
            <OtpRoute path={`${path}energysavings/:id([0-9]+)`} component={EnergySaving} exact />
            <OtpRoute path={`${path}energysavings/:id([0-9]+)/detail`} component={EnergySavingDetail} exact />
            <OtpRoute path={`${path}sensors`} component={PreloadSensors} exact />
            <OtpRoute path={`${path}sensors/:id([0-9]+)`} component={PreloadSensor} exact />
            <OtpRoute path={`${path}alerts`} component={PreloadAlerts} exact />
            <OtpRoute path={`${path}alerts/add`} component={PreloadAlert} exact />
            <OtpRoute path={`${path}alerts/:id([0-9]+)`} component={PreloadAlert} exact />
            <OtpRoute path={`${path}alerts_activity`} component={AlertEvents} exact />
            <OtpRoute path={`${path}alerts_packs`} component={PreloadAlertPacks} exact />
            <OtpRoute path={`${path}alerts_packs/:id_pack([0-9]+)`} component={PreloadAlertPack} exact />
            <OtpRoute path={`${path}alerts_packs/add`} component={PreloadAlertPack} exact />
            <OtpRoute path={`${path}reports`} component={Reports} exact />
            <OtpRoute path={`${path}reports/add`} component={Report} exact />
            <OtpRoute path={`${path}reports/:id([0-9]+)`} component={Report} exact />
            <OtpRoute path={`${path}reports/:id([0-9]+)/cover`} component={Cover} exact />
            <OtpRoute path={`${path}advancedanalytics`} component={AnalysisAdvanced} exact />
            <OtpRoute path={`${path}advancedanalytics/:id([0-9]+)`} component={AnalysisAdvanced} exact />
            <OtpRoute path={`${path}globalview`} component={PreloadGlobalview} exact />
            <OtpRoute path={`${path}machines/:id_machine([0-9]+)/events`} component={MachineEvents} exact />
            <OtpRoute path={`${path}machines/:id_machine([0-9]+)/events/add`} component={MachineEvent} exact />
            <OtpRoute path={`${path}machines/:id_machine([0-9]+)/events/:id_event([0-9]+)`} component={MachineEvent} exact />
            <OtpRoute path={`${path}machines/:id_machine([0-9]+)/maintenanceactions/declaration`} component={Declaration} exact />
            <OtpRoute path={`${path}manage`} component={LandingAdmin} exact />
            {/* PREDICT PART */}
            <OtpRoute path={`${path}kinematic/:id_machine([0-9]+)/:mode(change|view)`} component={PreloadKinematic} exact />
            <OtpRoute path={`${path}users/:id([0-9]+)/:mode(change|view)`} component={User} exact />
            <OtpRoute path={`${path}users/:mode(add)`} component={User} exact />
            <OtpRoute path={`${path}sites`} component={Sites} exact />
            <OtpRoute path={`${path}sites/add`} component={Site} exact />
            <OtpRoute path={`${path}sites/:id_site([0-9]+)/:mode(change|view)`} component={Site} exact />
            <OtpRoute path={`${path}mailings`} component={Mailings} exact />
            <OtpRoute path={`${path}mailing/add`} component={Mailing} exact />
            <OtpRoute path={`${path}mailing/:id_mailing([0-9]+)/:mode(change|view)`} component={Mailing} exact />
            <OtpRoute path={`${path}hierarchy`} component={Hierarchy} exact />
            <OtpRoute path={`${path}hierarchy/:id([0-9]+)`} component={Hierarchy} exact />
            <OtpRoute path={`${path}tags`} component={Tags} exact />
            <OtpRoute path={`${path}importeddatasets`} component={ImportedDataSets} exact />
            <OtpRoute path={`${path}importeddatasets/:dataset_id([0-9]+)/productiondata`} component={ProductionDatas} exact />
            <OtpRoute path={`${path}activitylogs`} component={ActivityLog} exact />
            <OtpRoute path={`${path}activitylogs/:id_type(1|2|3|5)`} component={ActivityLog} exact />
            <OtpRoute path={`${path}usages`} component={Usages} exact />
            <OtpRoute path={`${path}customdashboard`} component={DashboardDnd} exact />
            <OtpRoute path={`${path}customdashboard/:id([0-9]+)`} component={DashboardDnd} exact />
            <OtpRoute path={`${path}imports`} component={Imports} exact />
            <OtpRoute path={`${path}imports/api`} component={ApiImports} exact />
            <OtpRoute path={`${path}imports/api/:mode(add)`} component={ApiImport} exact />
            <OtpRoute path={`${path}imports/api/:id_file([0-9]+)/:mode(change|view)`} component={ApiImport} exact />
            <OtpRoute path={`${path}imports/:mode(add)`} component={Import} exact />
            <OtpRoute path={`${path}imports/:id_import([0-9]+)/:mode(change|view)`} component={Import} exact />
            <OtpRoute path={`${path}imports/:id_source([0-9]+)/file/:mode(add)`} component={File} exact />
            <OtpRoute path={`${path}imports/:id_source([0-9]+)/file/:id_file([0-9]+)/:mode(change|view)`} component={File} exact />
            <Route path={`${path}otp/setup`} component={OtpSetup} exact />
            <Route path={`${path}otp/validate`} component={OtpValidate} exact />
            {/* Only admin can go to these pages */}
            {rights?.is_admin && <OtpRoute path={`${path}users`} component={Users} exact />}
            {rights?.is_admin && <OtpRoute path={`${path}organization`} component={Organization} exact />}

            <Route component={Page404} />
        </Switch>
    );
};

RoutingLayer.propTypes = {
    path: PropTypes.string.isRequired
};
export default RoutingLayer;
