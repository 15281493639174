import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Button, Icon, List, Modal, Segment, Table } from "semantic-ui-react";
import { toast } from "react-toastify";

import history_app from "history_app";
import i18n from "modules/i18n/i18nConfig";
import { useKinematicChangeMutation } from "modules/machine/machineService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { previousStep } from "../kinematicSlice";
import { setCurrentEvent, setEventForm } from "modules/machine/machineSlice";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { Media } from "App";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const KinematicSubmitForm = (props) => {
    const dispatch = useDispatch();
    const { id_machine } = useParams();
    const [open, setOpen] = useState(false);
    const [waitConfirmation, setWaitConfirmation] = useState(false);
    const { kinematic, org, machine } = useSelector((state) => state);

    const last_kinematic_version = _.chain(machine).get("machine.current", null).get("last_kinematic_version", null).value();

    //Retrieve event.
    //Case 1: From current if kinematic changed (event has pk)
    //Case 2: Directly from event form
    const event_form =
        machine?.event?.current && machine?.event?.current?.machine === parseInt(id_machine) ? machine.event.current : machine.event.form;

    const current_machine = useMemo(() => {
        if (_.size(machine.machines) > 0) {
            return _.find(machine.machines, { id: parseInt(id_machine) }) || null;
        }
        return null;
    }, [machine.machines, id_machine]);

    const [kinematicChange, change] = useKinematicChangeMutation();

    const kin_change_params = {
        org: org.current,
        machine: machine.machine.current,
        kinematic: { ...kinematic.kinematic, is_confirmation: waitConfirmation },
        event: event_form
    };

    useEffect(() => {
        //trigger request when we come on step
        kinematicChange(kin_change_params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kinematicChange]);

    useEffect(() => {
        (async () => {
            if (change.isFetching) {
                await toast.info(i18n._(t`send request to server`), toast_options);
            }
            if (change.isSuccess) {
                const is_confirmation = _.get(change, "originalArgs.kinematic.is_confirmation", false);
                if (is_confirmation) {
                    await toast.success(i18n._(t`cinematic validation success`), toast_options);
                }

                await setTimeout(async () => {
                    if (waitConfirmation === false) {
                        setOpen(true);
                        setWaitConfirmation(true);
                    } else {
                        const id_eqpmt = current_machine?.equipment;
                        if (id_eqpmt) {
                            if (last_kinematic_version === 0) {
                                //Supposed to be 1st kinematic
                                history_app.push(`/equipments/${id_eqpmt}#machineKinematic`);
                            } else if (event_form) {
                                if (_.get(event_form, "event_type") === 7) {
                                    //Reset event form
                                    dispatch(setEventForm(null));
                                    dispatch(setCurrentEvent(null));
                                    //return to events list if kinematic_correction
                                    history_app.push(`/equipments/${id_eqpmt}#machineEvents`);
                                } else {
                                    await history_app.push(`/machines/${id_machine}/maintenanceactions/declaration`);
                                }
                            }
                        }
                    }
                }, 1000);
            }
            if (change.isError) {
                let error = i18n._(t`cinematic validation error`);
                if (change.error?.data && !_.includes(change.error?.data, "<!DOCTYPE html>")) {
                    error = <RequestErrorRender errors={change.error?.data} />;
                }
                toast(error, { ...toast_options_err, type: "error" });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change]);

    //Used to display specific message when no changes in kinematic
    const no_change = _.every(
        [_.get(change, "data.hs_modified", []), _.get(change, "data.hs_created", []), _.get(change, "data.hs_removed", [])],
        _.isEmpty
    );

    return (
        <Segment.Group>
            {change.isError && (
                <>
                    <MessageDisplay message={i18n._(t`cinematic validation error`)} level="error" iconName="warning circle" isLoading={false} />
                    <Segment textAlign="center" attached>
                        <Button
                            type="button"
                            icon="left arrow"
                            labelPosition="left"
                            content={i18n._(t`previous`)}
                            onClick={(e) => {
                                dispatch(previousStep());
                            }}
                        />
                        <Button
                            icon="redo alternate"
                            labelPosition="left"
                            content={i18n._(t`retry`)}
                            //retry btn when request failed
                            onClick={() => {
                                kinematicChange(kin_change_params);
                            }}
                        />
                    </Segment>
                </>
            )}
            {change.isFetching && (
                <MessageDisplay message={i18n._(t`in progress cinematic`)} level="info" iconName="circle notched" isLoading={true} />
            )}
            {change.isSuccess && (
                <MessageDisplay message={`${i18n._(t`cinematic validation success`)}`} level="success" iconName="check" isLoading={false} />
            )}
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                centered={false}
                closeOnDimmerClick={false}
                closeOnEscape={false}
            >
                <Modal.Header>
                    <Trans>Confirmation</Trans>
                </Modal.Header>
                <Modal.Content>
                    {_.get(change, "data.cluster_reset") === true && (
                        <p>
                            <Trans>All Health scores and machine clusters will be reset</Trans>
                        </p>
                    )}
                    {_.get(change, "data.cluster_reset") === false && (
                        <>
                            {_.get(event_form, "event_date") !== undefined && (
                                <Segment basic>
                                    <Trans>Consequences</Trans>
                                </Segment>
                            )}
                            {no_change && (
                                <>
                                    <p style={{ fontWeight: "bold", fontStyle: "italic", marginLeft: "10%" }}>
                                        <Trans>The changes entered will not impact the calculation of health scores</Trans>
                                    </p>
                                    <p>
                                        <Trans>If you agree with the changes, please confirm</Trans>
                                    </p>
                                </>
                            )}
                            {!no_change && (
                                <>
                                    <Table celled definition>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>
                                                    <Trans>Elements</Trans>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    <Trans>hs_modified</Trans>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <List>
                                                        {_.chain(change)
                                                            .get("data.hs_modified", [])
                                                            .map((hs, idx) => {
                                                                return (
                                                                    <List.Item key={idx}>{`${i18n._(
                                                                        _.get(hs, "component__comp_type__name", "-")
                                                                    )} / ${i18n._(_.get(hs, "component__name", "-"))} / ${i18n._(
                                                                        _.get(hs, "healthscore_type__name", "-")
                                                                    )}`}</List.Item>
                                                                );
                                                            })
                                                            .value()}
                                                    </List>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    <Trans>hs_created</Trans>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <List>
                                                        {_.chain(change)
                                                            .get("data.hs_created", [])
                                                            .map((hs, idx) => {
                                                                return (
                                                                    <List.Item key={idx}>{`${i18n._(
                                                                        _.get(hs, "component__comp_type__name", "-")
                                                                    )} / ${i18n._(_.get(hs, "component__name", "-"))} / ${i18n._(
                                                                        _.get(hs, "healthscore_type__name", "-")
                                                                    )}`}</List.Item>
                                                                );
                                                            })
                                                            .value()}
                                                    </List>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    <Trans>hs_removed</Trans>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <List>
                                                        {_.chain(change)
                                                            .get("data.hs_removed", [])
                                                            .map((hs, idx) => {
                                                                return (
                                                                    <List.Item key={idx}>{`${i18n._(
                                                                        _.get(hs, "component__comp_type__name", "-")
                                                                    )} / ${i18n._(_.get(hs, "component__name", "-"))} / ${i18n._(
                                                                        _.get(hs, "healthscore_type__name", "-")
                                                                    )}`}</List.Item>
                                                                );
                                                            })
                                                            .value()}
                                                    </List>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <p>
                                        <Trans>If you agree with the changes, please confirm</Trans>
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Media greaterThanOrEqual="tablet">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <>
                                    <Button
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={() => {
                                            setWaitConfirmation(false);
                                            setOpen(false);
                                            dispatch(previousStep());
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    <Button
                                        positive
                                        icon
                                        labelPosition="right"
                                        onClick={() => {
                                            setOpen(false);
                                            kinematicChange({ ...kin_change_params, kinematic: { ...kinematic.kinematic, is_confirmation: true } });
                                        }}
                                        disabled={change.isError || change.isFetching}
                                    >
                                        <Icon name="send" />
                                        <Trans>Confirm</Trans>
                                    </Button>
                                </>
                            )
                        }
                    </Media>
                    <Media lessThan="tablet">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <>
                                    <Button
                                        negative
                                        icon
                                        onClick={() => {
                                            setWaitConfirmation(false);
                                            setOpen(false);
                                            dispatch(previousStep());
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                    </Button>
                                    <Button
                                        positive
                                        icon
                                        onClick={() => {
                                            setOpen(false);
                                            kinematicChange({ ...kin_change_params, kinematic: { ...kinematic.kinematic, is_confirmation: true } });
                                        }}
                                    >
                                        <Icon name="check" />
                                    </Button>
                                </>
                            )
                        }
                    </Media>
                </Modal.Actions>
            </Modal>
        </Segment.Group>
    );
};

export default KinematicSubmitForm;
