import axios from "axios";
import cookie from "react-cookies";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import store from "store";
import { defaultLng } from "modules/i18n/i18nConfig";

export const djangoAdmin = (lng) => `/${lng}/admin/`;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export const refreshTimestampUrlParam = (tmst = null) => {
    const refresh_timestamp = tmst ? tmst : cookie.load("refresh_timestamp");
    if (refresh_timestamp) {
        return `_${refresh_timestamp}`;
    }
    return "";
};

const axiosInstance = axios.create({
    baseURL: `/api/v1/`,
    withCredentials: true,
    headers: { "X-CSRFTOKEN": cookie.load("csrftoken") },
    timeout: 60000
});

axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const language = state.i18n.current || defaultLng;
        if (language) {
            config.headers["Accept-Language"] = language;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;

export const defaultApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 600,
    tagTypes: [
        "Sensors",
        "Sensor",
        "Sensortype",
        "Usage",
        "Usages",
        "Tag",
        "Tags",
        "Unit",
        "Dataflows",
        "Site",
        "Sites",
        "Measurements",
        "Measurementtype",
        "Equipments",
        "Equipment",
        "EquipmentMeasurements",
        "Equipmenttype",
        "Machines",
        "Machinetype",
        "Healthscoretype",
        "Components",
        "Componenttype",
        "Alerts",
        "Alert",
        "Mailings",
        "Mailing",
        "Exports",
        "Export",
        "MachineEvent",
        "MachineEvents",
        "MaintenanceActionTypes",
        "Station",
        "Zones",
        "Zone",
        "Calendars",
        "Calendar",
        "CalendarExceptions",
        "CalendarException",
        "Dnds",
        "Dnd",
        "Widgets",
        "Widget",
        "Imports",
        "Import",
        "TestImport",
        "FileImports",
        "FileImport",
        "Dashboard",
        "Dashboards",
        "LastActivity",
        "InstantExport",
        "SortedDashboards",
        "ComparisonWidgets",
        "ApiImports",
        "ApiImport",
        "LoginHistory",
        "ActivityLog",
        "DataflowsLastValues",
        "MachinesLastValues",
        "MachineOverview24h",
        "MeasurementOverview24h",
        "MeasurementLastValues",
        "ComparisonDetail",
        "ComparisonConso",
        "ComparisonSummary",
        "TriggeredAlerts",
        "EventAlerts",
        "ImportedDataSet",
        "ImportedDataSets",
        "ProductionDatas",
        "ProductionData",
        "EquipmentEfficiency"
    ],
    reducerPath: "apiDefault",
    endpoints: () => ({})
});
