import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import moment from "moment";
import { Grid, Header, Segment, Divider, Button, Input, Icon, Message, Container } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import Datetime from "react-datetime";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import {
    exportTypeOptions,
    sendprotocolOptions,
    frequencyOptions,
    daysOptions,
    historicOptions,
    exportFormatOptions,
    exportInstantOptions
} from "../utils";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { useCreateExportMutation, useGetExportsQuery, useUpdateExportMutation } from "../exportService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import history_app from "history_app";

import Back from "modules/common/components/back";
import { renderError, ToggleAdapter, DropDownAdapter, InputAdapter, TextAreaAdapter } from "modules/common/components/form";
import DropdownAdapterMeasurement from "./DropdownAdapterMeasurement";
import MailingAddModalForm from "modules/mailing/components/MailingAddModalForm";
import { default_export } from "../exportSlice";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};
    if (!values.hours) {
        errors.hours = <Trans>hours is required</Trans>;
    }
    if (isNaN(Date.parse(`01/01/2020 ${values.hours}`))) {
        errors.hours = <Trans>invalid hour</Trans>;
    }
    if (_.get(values, "frequency") === "1" && (isNaN(values.dayofweek) || values.dayofweek < 0 || values.dayofweek > 5)) {
        errors.dayofweek = <Trans>invalid dayofweek</Trans>;
    }
    if (values.measurements.length === 0) {
        errors.measurements = <Trans>measurements can't be empty</Trans>;
    }
    return errors;
};

export const ExportTimerAdapter = ({ ...rest }) => {
    const now = moment();
    let export_date = Date.parse(`01/01/2020 ${rest.values.hours}`);
    const renderInput = (props, openCalendar, closeCalendar) => {
        return (
            <div
                className={`field ${props.isRequired ? "required" : ""} ${props.meta.error && props.meta.touched ? "error" : ""}`}
                style={{ marginBottom: "1em" }}
            >
                <label>{props.label}</label>
                {renderError(props.meta)}
                <Input fluid icon disabled={rest?.disabled}>
                    <input
                        readOnly
                        autoComplete="off"
                        {...props.input}
                        onClick={openCalendar}
                        value={isNaN(export_date) ? props.input.value : moment(export_date).locale("fr").format("LT")}
                    />
                    <Icon name="calendar" />
                </Input>
            </div>
        );
    };

    return (
        <Datetime
            locale={rest.locale}
            value={export_date}
            dateFormat={false}
            timeFormat="HH:mm"
            renderInput={renderInput}
            inputProps={{ ...rest }}
            onChange={(moment_obj) => {
                if (!_.isString(moment_obj)) {
                    rest.input.onChange(moment_obj.locale(rest.locale).format("LT"));
                }
            }}
            isValidDate={(current) => {
                return current.isBefore(now);
            }}
        />
    );
};

const Export = (props) => {
    const { mailings, measurements, sites, usages } = props;
    const { id } = useParams();
    const { auth, org, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const can_change = notification.srv_status.db_status === "rw";

    const current_expt = useMemo(() => {
        return { ...default_export, ...props.export };
    }, [props.export]);

    const [createExport, create] = useCreateExportMutation();
    const [updateExport, update] = useUpdateExportMutation();
    const expts = useGetExportsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create export`), toast_options);
            history_app.push(`/exports`);
        }
        if (create.isError) {
            let error = i18n._(t`cannot create export`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update export`), toast_options);
            history_app.push(`/exports`);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update export`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const submitForm = async (formData) => {
        if (can_change) {
            const data = _.mapValues(formData, (item, key) => {
                if (_.includes(["dayofmonth", "ftp_port"], key)) {
                    return reparseNumber(item);
                }
                return item;
            });

            if (_.get(data, "id") === undefined) {
                createExport({ org: org.current, user: auth.user, data });
            } else {
                updateExport({ org: org.current, user: auth.user, data });
            }
        }
    };

    const mailings_id = useMemo(() => {
        return _.map(mailings, (item) => item.id);
    }, [mailings]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id === undefined && <Trans>add export</Trans>}
                            {id !== undefined && <Trans>update export</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container>
                            <Form
                                onSubmit={submitForm}
                                initialValues={current_expt}
                                validate={validate}
                                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                    const not_visible_mailings = _.chain(values.mailinglist).difference(mailings_id).size().value() > 0;
                                    return (
                                        <form onSubmit={handleSubmit} className="ui form">
                                            <Field name="is_active" label={i18n._(t`is_active`)} labelposition="right" component={ToggleAdapter} />
                                            <Field
                                                name="name"
                                                placeholder={i18n._(t`enter name of export`)}
                                                label={i18n._(t`name`)}
                                                isRequired={true}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    const existing_name = _.chain(expts.data || [])
                                                        .filter((expt) => {
                                                            return expt.id !== parseInt(id);
                                                        })
                                                        .find({ name: value })
                                                        .value();

                                                    if (existing_name) {
                                                        return <Trans>an export with this name exists</Trans>;
                                                    }
                                                    if (!value) {
                                                        return <Trans>export name is required</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                            <Field
                                                name="description"
                                                placeholder={i18n._(t`enter description of your export`)}
                                                label={i18n._(t`description`)}
                                                component={TextAreaAdapter}
                                            />
                                            <Divider horizontal>
                                                <Trans>timing selection</Trans>
                                            </Divider>
                                            <Message size="small">
                                                <Message.Header>
                                                    <Trans>schedule definition</Trans>
                                                </Message.Header>
                                                <p>
                                                    <Trans>schedule definition content</Trans>
                                                </p>
                                            </Message>
                                            <Field
                                                name="frequency"
                                                label={i18n._(t`frequency`)}
                                                options={frequencyOptions}
                                                component={DropDownAdapter}
                                            />
                                            {values.frequency === "1" && (
                                                <Field
                                                    name="dayofweek"
                                                    label={i18n._(t`dayofweek`)}
                                                    placeholder={i18n._(t`choose the day of the week`)}
                                                    options={daysOptions}
                                                    component={DropDownAdapter}
                                                />
                                            )}
                                            {values.frequency === "2" && (
                                                <Field
                                                    name="dayofmonth"
                                                    label={i18n._(t`dayofmonth`)}
                                                    placeholder={i18n._(t`choose the day of the month`)}
                                                    component={InputAdapter}
                                                    parse={identityNull}
                                                    inputMode="numeric"
                                                    validate={(value) => {
                                                        const dayValidate = validateNumber(value, i18n, false, false, true);
                                                        if (dayValidate !== undefined) {
                                                            return dayValidate;
                                                        }
                                                        const day = reparseNumber(value);
                                                        if (day < 1 || day > 31) {
                                                            return <Trans>invalid dayofmonth - number between 1 to 31</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            )}
                                            <Field
                                                name="hours"
                                                label={i18n._(t`hours`)}
                                                values={values}
                                                isRequired={true}
                                                locale={current_lng}
                                                component={ExportTimerAdapter}
                                            />
                                            <Field
                                                name="historic"
                                                label={i18n._(t`historic`)}
                                                isRequired={true}
                                                options={values.export_type === 2 ? _.drop(historicOptions) : historicOptions}
                                                component={DropDownAdapter}
                                            />
                                            <Divider horizontal>
                                                <Trans>transmission</Trans>
                                            </Divider>
                                            <Field
                                                name="send_protocol"
                                                label={i18n._(t`send_protocol`)}
                                                isRequired={true}
                                                options={sendprotocolOptions}
                                                component={DropDownAdapter}
                                            />
                                            {values.send_protocol === "email" && (
                                                <>
                                                    <Grid verticalAlign="bottom">
                                                        <Grid.Column
                                                            mobile={can_change ? 13 : 16}
                                                            tablet={can_change ? 15 : 16}
                                                            computer={can_change ? 15 : 16}
                                                        >
                                                            <Field
                                                                name="mailinglist"
                                                                label={i18n._(t`mailinglists`)}
                                                                placeholder={i18n._(t`choose mailinglists`)}
                                                                options={mailings}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                noResultsMessage={i18n._(t`no mailinglists found`)}
                                                                validate={(value) => {
                                                                    if (value.length === 0) {
                                                                        return <Trans>mailinglist can't be empty</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        {can_change && (
                                                            <Grid.Column width={1} textAlign="center">
                                                                <MailingAddModalForm form={form} mailings={mailings} />
                                                            </Grid.Column>
                                                        )}
                                                    </Grid>
                                                    {not_visible_mailings && (
                                                        <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                            <Trans>Some mailing lists are present but you do not have permission to view them</Trans>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {_.includes(["ftp", "ftps", "sftp"], values.send_protocol) && (
                                                <Grid centered verticalAlign="middle">
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Field
                                                            name="ftp_server"
                                                            label={i18n._(t`ftp_server`)}
                                                            placeholder={i18n._(t`server/folder/path`)}
                                                            component={InputAdapter}
                                                            validate={(value) => {
                                                                if (!value) {
                                                                    return <Trans>ftp server name can't be empty</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Field
                                                            name="ftp_port"
                                                            label={i18n._(t`ftp_port`)}
                                                            placeholder={i18n._(t`enter your ftp port`)}
                                                            component={InputAdapter}
                                                            parse={identityNull}
                                                            inputMode="numeric"
                                                            validate={(value) => {
                                                                return validateNumber(value, i18n, false, false, true);
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Field
                                                            name="ftp_user"
                                                            label={i18n._(t`ftp_user`)}
                                                            placeholder={i18n._(t`enter your ftp user`)}
                                                            component={InputAdapter}
                                                            validate={(value) => {
                                                                if (!value) {
                                                                    return <Trans>ftp user can't be empty</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Field
                                                            name="ftp_password"
                                                            label={i18n._(t`ftp_password`)}
                                                            type="password"
                                                            placeholder={i18n._(t`enter your ftp password`)}
                                                            component={InputAdapter}
                                                            validate={(value) => {
                                                                if (!value) {
                                                                    return <Trans>ftp password can't be empty</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid>
                                            )}
                                            <Divider horizontal>
                                                <Trans>content to be sent</Trans>
                                            </Divider>
                                            <Field
                                                name="export_type"
                                                label={i18n._(t`export_type`)}
                                                isRequired={true}
                                                options={exportTypeOptions}
                                                component={DropDownAdapter}
                                                customAction={(data) => {
                                                    if (data === 2 && values.historic === 0) {
                                                        form.change("historic", 1);
                                                    }
                                                }}
                                            />
                                            <Field
                                                name="format"
                                                label={i18n._(t`export_format`)}
                                                isRequired={true}
                                                options={exportFormatOptions}
                                                component={DropDownAdapter}
                                                upward
                                            />
                                            <Field
                                                name="measurements"
                                                label={i18n._(t`measurements`)}
                                                placeholder={i18n._(t`choose your measurements here`)}
                                                options={measurements}
                                                multipleselect={1}
                                                sites={sites}
                                                usages={usages}
                                                component={DropdownAdapterMeasurement}
                                                noResultsMessage={i18n._(t`no result found`)}
                                                open={false}
                                                isRequired={true}
                                            />
                                            {values.export_type === 1 && (
                                                <Field
                                                    name="instant_values"
                                                    label={i18n._(t`instant_values_export`)}
                                                    isRequired={true}
                                                    options={exportInstantOptions}
                                                    component={DropDownAdapter}
                                                />
                                            )}
                                            {can_change && (
                                                <Button type="submit" content={i18n._(t`validate`)} disabled={submitting || pristine || invalid} />
                                            )}
                                        </form>
                                    );
                                }}
                            />
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Export;
