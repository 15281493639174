import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Divider, Grid, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

import { checkRights } from "modules/auth/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useUpdateEquipmentMutation } from "modules/equipment/equipmentService";

import EquipmentMainFields from "./EquipmentMainFields";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const EquipmentMainForm = (props) => {
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const [updateEquipment, update] = useUpdateEquipmentMutation();

    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update equipment`), toast_options);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update equipment`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    const initialValues = useMemo(() => {
        return {
            equipment: props.equipment
        };
    }, [props.equipment]);

    const can_change = useMemo(() => {
        const rights = checkRights(props?.equipment, auth.rights);
        // TODO CHANGE for add Equipment. Site not define. Need another way to get right.
        // Now: validate btn enable for add. Backend check rights
        return _.includes(rights, "can_change");
    }, [auth.rights, props.equipment]);

    const submitForm = (formData) => {
        updateEquipment({ org: org.current, data: formData?.equipment });
    };

    return (
        <Form
            onSubmit={submitForm}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, invalid, values, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={14} computer={14}>
                                    <EquipmentMainFields form={form} values={values} can_change={can_change} />
                                    {(can_change || (props?.equipment?.id === undefined && _.size(auth.rights?.sites_rw) > 0)) &&
                                        notification.srv_status.db_status === "rw" && (
                                            <>
                                                <Divider />
                                                <Segment basic>
                                                    <Button
                                                        type="submit"
                                                        positive
                                                        content={i18n._(t`validate`)}
                                                        disabled={submitting || pristine || invalid}
                                                    />
                                                </Segment>
                                            </>
                                        )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                );
            }}
        />
    );
};

export default React.memo(EquipmentMainForm);
