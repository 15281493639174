import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { Button, Popup, Grid, Input, Icon, Dropdown } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { checkRights } from "modules/auth/utils";
import { eventTypeOptions } from "modules/machine/utils";
import {
    setEventSearchNameFilter,
    setEvtTypeFilter,
    setEventPage,
    setEventItemsPerPage,
    resetEventFilterWithPage
} from "modules/machine/machineSlice";
import { useGetMachineEventsQuery, useGetMachinesQuery } from "modules/machine/machineService";

import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DeleteMachineEvent from "modules/machine/components/event/DeleteMachineEvent";

const PaneEvents = (props) => {
    const { equipment } = props;
    const dispatch = useDispatch();
    const [localSearchName, setLocalSearchName] = useState("");
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const notification = useSelector((state) => state.notification);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng
    const machine = useSelector((state) => state.machine);

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machine_events = useGetMachineEventsQuery({ org: org.current, id_machine: equipment?.machine?.id }, { skip: !org.current });

    const error_list = [machine_events.isError, machines.isError];
    const loading_list = [machine_events.isFetching, machines.isFetching];
    const status_list = [machine_events.isSuccess, machines.isSuccess];

    useEffect(() => {
        (async () => {
            if (_.every([...status_list])) {
                await setLocalSearchName(machine.event.filter.searchName);
                await dispatch(setEventSearchNameFilter(machine.event.filter.searchName));
                await dispatch(setEvtTypeFilter(machine.event.filter.evtTypeFilter));
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, status_list]);

    const headCells = useMemo(() => {
        return [
            { id: "id", label: "id", textAlign: "right" },
            { id: "title", label: i18n._(t`name`), textAlign: "left" },
            { id: "event_date", label: i18n._(t`event_date`), textAlign: "left" },
            { id: "event_type", label: i18n._(t`event_type`), textAlign: "left" },
            { id: "declaration_date", label: i18n._(t`declaration_date`), textAlign: "left" },
            { id: "owner_email", label: i18n._(t`owner`), textAlign: "left" },
            { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
        ];
        // eslint-disable-next-line
    }, [current_lng]);

    // Check rights based on machine's site
    // Admin or Technician can add machine events
    const current_machine = _.find(machines.data, { id: equipment?.machine?.id });
    const rights = checkRights(current_machine, auth.rights);

    const events_list = _.chain(machine_events)
        .get("data", [])
        .reduce((res, event) => {
            if (machine.event.filter.searchName === "") {
                res.push(event);
            } else if (_.includes(event.title.toLowerCase(), machine.event.filter.searchName.toLowerCase())) {
                res.push(event);
            }
            return res;
        }, [])
        .reduce((res, event) => {
            if (_.size(machine.event.filter.evtTypeFilter) === 0) {
                res.push(event);
            } else if (_.includes(machine.event.filter.evtTypeFilter, event.event_type)) {
                res.push(event);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const evt_type = _.chain(eventTypeOptions).find({ key: item.event_type }).get("text").value();
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                title: {
                    render: <span style={customStyle}>{_.get(item, "title", "-")}</span>,
                    value: _.get(item, "title", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                event_date: {
                    render:
                        item.event_date !== null ? (
                            <span style={customStyle}>{`${moment(item.event_date).locale(current_lng).format("L")} ${moment(item.event_date)
                                .locale(current_lng)
                                .format("LT")}`}</span>
                        ) : (
                            "-"
                        ),
                    value: item.event_date !== null ? moment(item.event_date) : null,
                    textAlign: "left",
                    datatype: "date"
                },
                event_type: {
                    render: <span style={customStyle}>{evt_type ? i18n._(evt_type) : "-"}</span>,
                    value: evt_type ? i18n._(evt_type) : null,
                    textAlign: "left",
                    datatype: "string"
                },
                declaration_date: {
                    render:
                        item.declaration_date !== null ? (
                            <span style={customStyle}>{`${moment(item.declaration_date).locale(current_lng).format("L")} ${moment(
                                item.declaration_date
                            )
                                .locale(current_lng)
                                .format("LT")}`}</span>
                        ) : (
                            "-"
                        ),
                    value: item.declaration_date !== null ? moment(item.declaration_date) : null,
                    textAlign: "right",
                    datatype: "date"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Popup
                                    trigger={
                                        <Button
                                            icon="eye"
                                            onClick={async (e) => {
                                                history_app.push(`/machines/${equipment?.machine?.id}/events/${item.id}`);
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        <Trans>event view</Trans>
                                    </Popup.Content>
                                </Popup>
                                {false && <DeleteMachineEvent item={item} />}
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    if (!_.isFinite(equipment?.machine?.id)) {
        return <MessageDisplay message={i18n._(t`Unknown machine`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (_.some([...loading_list])) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (_.some([...error_list])) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (_.every([...status_list])) {
        return (
            <Grid celled>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                    <Input
                        fluid
                        icon="search"
                        placeholder={i18n._(t`search title of event`)}
                        onChange={(e, { value }) => {
                            setLocalSearchName(value);
                            dispatch(setEventSearchNameFilter(value));
                        }}
                        value={localSearchName}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                    <Dropdown
                        fluid
                        options={_.map(eventTypeOptions, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                        placeholder={i18n._(t`select event type`)}
                        multiple
                        selection
                        onChange={(e, { value }) => {
                            dispatch(setEvtTypeFilter(value));
                        }}
                        value={machine.event.filter.evtTypeFilter}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                        <Button
                            icon
                            labelPosition="left"
                            onClick={async (e) => {
                                await dispatch(resetEventFilterWithPage());
                                history_app.push(`/machines/${equipment?.machine?.id}/events/add`);
                            }}
                        >
                            <Icon name="add" />
                            <Trans>add machine event</Trans>
                        </Button>
                    )}
                </Grid.Column>
                <Grid.Column width={16}>
                    <TableEnhanced
                        headCells={headCells}
                        rows={events_list}
                        textItemPerPages={i18n._(t`items per page`)}
                        page={machine.event.pagination.page}
                        rowsPerPage={machine.event.pagination.itemsPerPage}
                        setPage={(page) => {
                            dispatch(setEventPage(page));
                        }}
                        setItemsPerPage={(items) => {
                            dispatch(setEventItemsPerPage(items));
                        }}
                        order="desc"
                        orderBy="event_date"
                    />
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

export default React.memo(PaneEvents);
